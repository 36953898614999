import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { CSSTransition } from "react-transition-group"
import "./animation.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons"

const NavLink = styled.li`
  padding: 10px 0;
  cursor: pointer;
  font-weight: bold;

  .caret {
    margin-left: 10px;
    height: 20px;
  }

  @media (min-width: 1050px) {
    width: auto;
    height: 100%;
    margin: 0;

    display: flex;
    flex-wrap: wrap;

    color: ${p => p.theme.color.black};
    height: 75px;
    background: none;
    padding: 5px;
    margin: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: color 250ms;

    &:hover {
      color: ${p => p.theme.color.green.normal};
    }

    .DropDownLinks {
      overflow: visible;
    }
  }
`

const DropDown = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  background-color: ${p => p.theme.color.white};
  color: ${p => p.theme.color.black};

  @media (min-width: 1050px) {
    width: auto;
    position: absolute;
    height: auto;
    top: 75px;
    margin: 0;
    padding: 0;
    box-shadow: 0px 7px 15px rgba(130, 130, 130, 0.5);

    .dropw-down-wrapper {
      height: auto;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
`

const DropDownWrapper = styled.div`
  @media (min-width: 1050px) {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    align-self: top;
  }
`

const NavigationItem = props => {
  const [isOpen, setOpen] = useState(false)

  if (props.children) {
    return (
      <>
        <DropDownWrapper>
          <NavLink
            onTouchEnd={() => setOpen(!isOpen)}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
          >
            <div>
              {props.label}
              <FontAwesomeIcon
                className="caret"
                icon={(isOpen && faCaretUp) || faCaretDown}
              />
            </div>
            <CSSTransition
              className="DropDownLinks"
              in={isOpen}
              timeout={450}
              classNames="dropdown-animation"
              unmountOnExit
            >
              <DropDown>{props.children}</DropDown>
            </CSSTransition>
          </NavLink>
        </DropDownWrapper>
      </>
    )
  }

  return (
    <Link to={props.link}>
      <NavLink>{props.label}</NavLink>
    </Link>
  )
}

export default NavigationItem
