import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhone,
  faAt,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons"
import {
  faFacebook,
  faLinkedin,
  faInstagram,
  faYoutube,
  faVimeo,
} from "@fortawesome/free-brands-svg-icons"

const Wrapper = styled.footer`
  background-color: ${p => p.theme.color.green.normal};
  color: ${p => p.theme.color.white};
  width: 100%;
  padding-bottom: 20px;
  margin-top: 10px;
`

const ContentWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  .navigation-wrapper {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @media (min-width: 800px) {
      width: 40%;
    }
  }

  .contact-wrapper {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @media (min-width: 800px) {
      width: 60%;
    }
  }

  ul.navigation {
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > li {
      padding-left: 10px;
    }

    li {
      text-decoration: none;
      list-style: none;
      padding: none;
      margin: none;
      width: 183px;

      .section-title {
        font-size: ${p => p.theme.fontSize.normal};
        font-weight: bold;
        margin-top: 15px;
      }

      > ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      .section {
        margin: 10px;
      }
    }
  }
`

const Logo = styled.div`
  height: auto;
  a {
    font-size: 2em;
    padding: 8px 10px 10px 10px;
    font-weight: 700;
    color: ${p => p.theme.color.white};
    display: inline-block;
  }
`

const ContactDetails = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0 10px;

    li {
      padding: 3px 0;
      display: flex;
      align-items: center;

      .icon {
        margin-right: 10px;
        width: 25px;
      }
    }
  }
`
const SocialButtons = styled.div`
  padding: 10px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 800px) {
    justify-content: center;
  }
  .social-icon {
    color: ${p => p.theme.color.white};
    margin: 10px;
  }
`

const Footer = props => {
  return (
    <Wrapper>
      <ContentWrapper>
        <div className="contact-wrapper">
          <ContactDetails>
            <Logo>
              <Link to="/">
                <span>Veloo</span>ways<sup>&reg;</sup>
              </Link>
            </Logo>
            <ul>
              <li>
                <FontAwesomeIcon className="icon" icon={faPhone} size="nm" />
                <a href="tel:01723050302">0172 30 50 302</a>
              </li>
              <li>
                <FontAwesomeIcon className="icon" icon={faAt} size="nm" />
                <a href="mailto:hallo@velooways.com">hallo@velooways.com</a>
              </li>
              <li>
                <FontAwesomeIcon
                  className="icon"
                  icon={faMapMarkerAlt}
                  size="nm"
                />
                <a href="https://goo.gl/maps/UmfNHyABygCNKvoo7">
                  Heinrich-Hertz-Str. 4, <br />
                  14532 Kleinmachnow
                </a>
              </li>
            </ul>
          </ContactDetails>

          <SocialButtons>
            <a
              href="https://www.linkedin.com/company/velooways-gmbh"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                className="social-icon"
                icon={faLinkedin}
                size="2x"
              />
            </a>
            <a
              href="https://www.facebook.com/veloowaysgmbh"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                className="social-icon"
                icon={faFacebook}
                size="2x"
              />
            </a>
            {/* 
            <a href="" target="_blank" rel="noreferrer">
              <FontAwesomeIcon
                className="social-icon"
                icon={faInstagram}
                size="2x"
              />
            </a>
            */}
            {/* 
            <a href="" target="_blank" rel="noreferrer">
              <FontAwesomeIcon
                className="social-icon"
                icon={faYoutube}
                size="2x"
              />
            </a>
            */}
            <a href="https://vimeo.com/user120449083" target="_blank" rel="noreferrer">
              <FontAwesomeIcon
                className="social-icon"
                icon={faVimeo}
                size="2x"
              />
            </a>
          </SocialButtons>
        </div>

        <div className="navigation-wrapper ">
          <ul className="navigation">
            <li clssName="section">
              <div className="section-title">Produkte</div>
              <ul>
                <li>
                  <Link to="/powersoil">PowerSoil</Link>
                </li>
                <li>
                  <Link to="/powerpot">PowerPot</Link>
                </li>
                <li>
                  <Link to="/powerpex">PowerPex</Link>
                </li>
              </ul>
            </li>
            <li clssName="section">
              <div className="section-title">Lösungen</div>
              <ul>
                <li>
                  <Link to="/radwege">Radwege</Link>
                </li>
                <li>
                  <Link to="/straßen">Straßen</Link>
                </li>
                <li>
                  <Link to="/parkplätze">Parkplätze</Link>
                </li>
              </ul>
            </li>
             {/*
            <li clssName="section">
              <div className="section-title">Leistungen</div>
              <ul>
                <li>
                  <Link to="/planung">Planung</Link>
                </li>
                <li>
                  <Link to="/bau">Bau</Link>
                </li>
                <li>
                  <Link to="/lieferung">Lieferung</Link>
                </li>
              </ul>
            </li>
           
            <li clssName="section">
              <div className="section-title">Partner</div>
              <ul>
                <li>
                  <Link to="/radwege">Unsere Partner</Link>
                </li>
                <li>
                  <Link to="/straßen">Partner Werden</Link>
                </li>
                <li>
                  <Link to="/parkplätze">Partner Login</Link>
                </li>
              </ul>
            </li>
            */}

            <li clssName="section">
              <div className="section-title">Anderes</div>

              <ul>
                {/* 
                <li>
                  <Link to="/projekte">Projekte</Link>
                </li>
                */}
                <li>
                  <Link to="/hallo">Kontakt</Link>
                </li>
              </ul>
            </li>
            <li clssName="section">
              <div className="section-title">Rechtliches</div>
              <ul>
                <li>
                  <Link to="/datenschutz">Datenschutzhinweise</Link>
                </li>
                <li>
                  <Link to="/impressum">Impressum</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </ContentWrapper>
    </Wrapper>
  )
}

export default Footer
