import React, { useState } from "react"
import styled from "styled-components"
import { CSSTransition } from "react-transition-group"

import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"

const NavigationWrapper = styled.nav`
  height: 75px;
  width: 100%;
  width: 100vw;

  position: fixed;
  top: 0;
  background-color: ${p => p.theme.color.white};

  box-shadow: 0px 7px 15px rgba(130, 130, 130, 0.5);

  display: flex;

  z-index: 2000;

`

const Logo = styled.div`
  height: 100%;

  a {
    font-size: 2em;
    padding: 8px 10px 10px 10px;
    font-weight: 700;
    color: ${p => p.theme.color.black};
    > span {
      color: ${p => p.theme.color.green.normal};
    }

    display: inline-block;
    height: 100%;
  }
`

const NavLinks = styled.ul`
  z-index: 3000;

  list-style: none;
  margin-left: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  padding-bottom: 90px;
  background-color: ${p => p.theme.color.green.normal};
  background: linear-gradient(270deg, #00e6d8 -97.1%, ${p => p.theme.color.green.normal} 100%);
  width: 100vw;
  color: ${p => p.theme.color.white};
  position: absolute;
  min-height: 100vh;
  text-align: center;

  @media (min-width: 1050px) {
    display: none;
  }
`

const DesktopNavLinks = styled.ul`
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;

  @media (min-width: 1050px) {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-left: auto;
    margin-right: 4px;
  }
`

const HamburgerMenu = styled.div`
  z-index: 5000;

  position: fixed;
  bottom: 10px;
  right: 50%;
  transform: translate(50%, -10px);

  display: flex;
  justify-content: space-around;
  align-items: center;

  cursor: pointer;

  height: 75px;
  width: 75px;
  background-color: ${p => p.theme.color.green.normal};
  background: linear-gradient(270deg, #00e6d8 -97.1%, ${p => p.theme.color.green.normal} 100%);
  box-shadow: ${p =>
    p.open ? "none" : "0px 7px 15px rgba(130, 130, 130, 0.5)"};
  color: ${p => p.theme.color.white};
  border-radius: ${p => p.theme.radius.circle};

  .icon {
    height: 40px;
  }

  @media (min-width: 450px) {
    bottom: 10px;
    right: 10px;
    transform: translate(-10px, -10px);
  }

  @media (min-width: 800px) {
    top: 10px;
    right: 0px;
    transform: translate(-0px, -10px);

    background: none;
    box-shadow: none;
    color: ${p => p.theme.color.black};
  }

  @media (min-width: 1050px) {
    display: none;
  }
`

const Navigation = props => {
  const [isOpen, setOpen] = useState(false)

  return (
    <NavigationWrapper>
      <Logo>
        <Link to="/">
          <span>Veloo</span>ways<sup>&reg;</sup>
        </Link>
      </Logo>
      <CSSTransition
        in={isOpen}
        timeout={450}
        classNames="menu-animation"
        unmountOnExit
      >
        <NavLinks>{props.children}</NavLinks>
      </CSSTransition>
      <DesktopNavLinks>{props.children}</DesktopNavLinks>

      <HamburgerMenu onClick={() => setOpen(!isOpen)} open={isOpen}>
        <FontAwesomeIcon className="icon" icon={(isOpen && faTimes) || faBars} size="2x" />
      </HamburgerMenu>
    </NavigationWrapper>
  )
}

export default Navigation
