import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
  html {
    font-size: ${p => p.theme.fontSize.rootSize};

  }

  body {
    background-color: ${p => p.theme.color.gray.lighter} ;
    color: ${p => p.theme.color.black} ;
  }

  * {
    font-size: ${p => p.theme.fontSize.rootSize};
    box-sizing: border-box;
  }

  h1 {
    font-size: ${p => p.theme.fontSize.bigger};
    font-weight: 700;
  
    margin: 0;
    margin-bottom: 0.5em;
  
    text-rendering: optimizeLegibility;
    line-height: 1.1;
  }
  
  h2 {
    font-size: ${p => p.theme.fontSize.big};
    font-weight: 700;
    margin: 0;
    margin-bottom: 1em;
  
    text-rendering: optimizeLegibility;
    line-height: 1.1;
  }
  
  h3,
  h4,
  h5,
  h6 {
    font-size: ${p => p.theme.fontSize.normal};
    font-weight: 400;
    margin: 0;
    margin-bottom: 1em;
  
    text-rendering: optimizeLegibility;
    line-height: 1.1;
  }
  
  p {
    font-size: ${p => p.theme.fontSize.normal};
    font-weight: 400;
    margin: 0;
    margin-bottom: 1em;
    padding: 0;
  }
  
  span {
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
  }
  
  small {
    font-size: ${p => p.theme.fontSize.small};
  }

  mark {
    background-color: ${p => p.theme.color.green.lighter};
    color: ${p => p.theme.color.black};
  }
`
