export default {
    color: {
      green: {
        normal: "#18e35f",
        lighter: "#b2f7c8",
        light: "#60ea7e",
        dark: "#0ba83b",
        darker: "#02541b"
      },
      yellow: {
        normal: "#e8c515",
        lighter: "#f2e6b3",
        light: "#e5cc63",
        dark: "#a3860d",
        darker: "#4f4003"
      },
      turquoise: {
        normal: "#1fe5d8",
        lighter: "#b3f4ed",
        light: "#62e8db",
        dark: "#0ca596",
        darker: "#035149"
      },
      blue: {
        normal: "#1a96e2",
        lighter: "#b3d8f4",
        light: "#62afe8",
        dark: "#0c64a5",
        darker: "#032f51"
      },
      red: {
        normal: "#e21a1a",
        lighter: "#f4b3b3",
        light: "#e86262",
        dark: "#a50c0c",
        darker: "#4f0303"
      },
      gray: {
        normal: "#a3a3a3",
        lighter: "#eeeeee",
        light: "#bfbfbf",
        dark: "#7d7d7d",
        darker: "#4f4f4f"
      },
      black: "#000000",
      white: "#ffffff"
    },

    radius: {
      normal: "8px",
      smaller: "2px",
      small: "4px",
      big: "12px",
      biger: "16px",

      circle: "50%"
    },

    fontSize: {
      rootSize: "20px",
      normal: "1em",
      bigger: "2.25em",
      big: "1.5em",
      small: ".667em",
      smaller: ".5em"
    }
}