import styled, { css } from "styled-components"

const Button = styled.div`
  transition: all 0.1s linear;
  text-decoration: none;

  margin-top: 10px;

  border-radius: ${p => p.theme.radius.normal};

  ${p => {
    if (p.secondary) {
      return css`
        box-shadow: inset 0 0 0 3px ${p.theme.color.green.normal};
        color: ${p.theme.color.green.normal};
      `
    } else if (p.tertiary) {
      return css`
        background: none;
        border: none;
        color: ${p.theme.color.green.normal};
      `
    } else {
      return css`
        background-color: #00e6d8;
        background: linear-gradient(270deg, #00e6d8 -97.1%, ${p.theme.color.green.normal} 100%);
        box-shadow: 0px 7px 15px rgba(48, 213, 133, 0.5);

        border: none;
      `
    }
  }}

  a,
  button {
    display: inline-block;

    color: ${p => (p.secondary || p.tertiary ? p.theme.color.green.normal : p.theme.color.white)};

    text-align: center;
    text-decoration: none;

    font-weight: bold;
    font-size: ${p => p.theme.fontSize.normal};

    width: 100%;

    padding: 13.4px 20px;
  }

  button {
    color: ${p => (p.secondary || p.tertiary ? p.theme.color.green.normal : p.theme.color.white)};
    border: none;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
  }

  &:hover {
    filter: brightness(110%);
    box-shadow: ${p =>
      p.secondary || p.tertiary
        ? "inset 0 0 0 3px " + p.theme.color.green.normal
        : "0px 10px 20px rgba(48, 213, 133, 0.5)"};
    transform: scale(102%);
  }

  &:active {
    transform: scale(98%);
    filter: brightness(100%);
    box-shadow: ${p =>
      p.secondary || p.tertiary
        ? "none"
        : "0px 4px 8px rgba(48, 213, 133, 0.5)"};
  }

  &:disabled {
    color: ${p => p.theme.color.white};
    background: linear-gradient(270deg, #e0e0e0 -97.1%, #e5e5e5 100%);
  }
`

export default Button
