import React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import styled, { ThemeProvider } from "styled-components"
import styleVariables from "../globalStyles/styleVariables"
import GlobalStyle from "../globalStyles/globalStyles"
import Navigation from "./Navigation/Navigation"
import NavigationItem from "./Navigation/NavigationItem"
import Footer from "./common/Footer"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"

const Main = styled.main`
  margin: 0 auto;
  margin-top: 75px;
`

const theme = styleVariables

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <CookieConsent
        location="bottom"
        buttonText="Alle Cookies Akzeptieren 🍪"
        declineButtonText="Nur notwendige Cookies"
        enableDeclineButton
        flipButtons={false}
        cookieName="gatsby-gdpr-google-analytics"
        style={{
          backgroundColor: styleVariables.color.green.darker,
          zIndex: "9000",
        }}
        buttonStyle={{
          backgroundColor: styleVariables.color.green.lighter,
          borderRadius: styleVariables.radius.normal,
        }}
        declineButtonStyle={{
          backgroundColor: styleVariables.color.red.lighter,
          color: styleVariables.color.black,
          borderRadius: styleVariables.radius.normal,
        }}
      >
        Auch auf dieser Seite werden Cookies verwendet. Wir werten mit ihnen die
        Nutzung der Website aus, um sie stetig zu optimieren und unseren Nutzern
        eine immer bessere Erfahrung bieten zu können. Weitere Infos und
        Optionen findest du in den{" "}
        <Link to="/datenschutz">Datenschutzhinweisen.</Link>
      </CookieConsent>

      <Navigation>
        <NavigationItem label="Produkte">
          <NavigationItem label="PowerSoil" link="/powersoil" />
          <NavigationItem label="PowerPot" link="/powerpot" />
          <NavigationItem label="PowerPex" link="/powerpex" />
        </NavigationItem>

        <NavigationItem label="Lösungen">
          <NavigationItem label="Radwege" link="/radwege" />
          <NavigationItem label="Straßen" link="/straßen" />
          <NavigationItem label="Parkplätze" link="/parkplätze" />
        </NavigationItem>

        {/**
        <NavigationItem label="Leistungen">
          <NavigationItem label="Planung" link="/planung" />
          <NavigationItem label="Bau" link="/bau" />
          <NavigationItem label="Lieferung" link="/lieferung" />
        </NavigationItem>F
        */}

        {/*
        <NavigationItem label="Partner">
          <NavigationItem label="Unsere Partner" link="/radwege" />
          <NavigationItem label="Partner Werden" link="/radwege" />
          <NavigationItem label="Partner Login" link="/radwege" />
        </NavigationItem>
        <NavigationItem label="Projekte" link="/" />
        */}

        <NavigationItem label="Kontakt" link="/hallo" />
      </Navigation>

      <Main>{children}</Main>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
